import AuthGuard from 'guards/AuthGuard';
import { Suspense, Fragment } from 'react';
import { Routes, BrowserRouter as Router, Route } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { routes } from './index.endpoints';

const renderRoute = ({ component: Component, ...route }) => {
  const Guard = route.isAuthenticated ? AuthGuard : Fragment
  const Layout = route.layout ? route.layout : Fragment;
  const fallback = route.useGeneralLoader ? (
    <CircularProgress
      sx={{
        position: 'fixed',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)'
      }}
    />
  ) : (
    <></>
  );

  return (
    <Route
      key={route.path}
      path={route.path}
      element={
        <Layout>
          <Suspense fallback={fallback}>
           <Guard>
            <Component />
          </Guard>
          </Suspense>
        </Layout>
      }
    />
  );
};

export const RoutesWrapper = () => {
  return (
    <Router>
      <Routes>{routes.map((route) => renderRoute(route))}</Routes>
    </Router>
  );
};

export default RoutesWrapper;
