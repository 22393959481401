import React, { useState } from 'react';
import TawkTo from 'lib/Tawk.to';
import {
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  styled,
  Divider,
  Drawer,
  IconButton,
  Box
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SignOutIcon from '@mui/icons-material/LogoutOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import { ReactComponent as Logo } from 'assets/svgs/logo.svg';
import { ReactComponent as PowerIcon } from 'assets/svgs/power.svg';
import { ReactComponent as HomeIcon } from 'assets/svgs/home.svg';
import { ReactComponent as CardIcon } from 'assets/svgs/card.svg';
import { ReactComponent as UsersIcon } from 'assets/svgs/users.svg';
import { ReactComponent as UserIcon } from 'assets/svgs/user.svg';
import { ReactComponent as NotificationIcon } from 'assets/svgs/notification.svg';
import { ReactComponent as FAQIcon } from 'assets/svgs/faq.svg';
import { useNavigate } from 'react-router-dom';
import { cardPaths, userPaths, accountPaths } from 'routes/paths';
import SendFeedback from 'pages/Dashboard/SubMenuComponents/FeedbackModal';
import { logOut } from 'helpers/authHelper';

const SubMenuItems = () => {
  const navigate = useNavigate();
  const [viewFeedbackModal, setViewFeedbackModal] = useState(false);
  const handleFeedback = () => {
    setViewFeedbackModal(true);
  };
  // const menuItems = [
  //   {
  //     label: 'Refer A Business',
  //     icon: UsersIcon
  //   },
  //   {
  //     label: 'Notifications',
  //     icon: NotificationIcon
  //   },
  //   {
  //     label: 'Support',
  //     icon: HeadphonesIcon
  //   },
  //   {
  //     label: 'Send Feedback',
  //     icon: FAQIcon
  //   },
  //   {
  //     label: 'Sign Out',
  //     icon: SignOutIcon
  //   }
  // ];
  return (
    <StyledSubMenuList>
      <Divider color="secondary" />

      {/* REFER */}
      <MenuItem disabled onClick={() => navigate('/cards/refer-business')}>
        <ListItemIcon>
          <UsersIcon color="primary" />
        </ListItemIcon>
        <ListItemText>Refer A Business</ListItemText>
      </MenuItem>

      {/* NOTIFICATION */}
      <MenuItem disabled onClick={() => navigate('')}>
        <ListItemIcon>
          <NotificationIcon color="primary" />
        </ListItemIcon>
        <ListItemText>Notifications</ListItemText>
      </MenuItem>

      {/* SUPPORT */}
      {/* <MenuItem onClick={() => navigate('')}>
        <ListItemIcon>
          <HeadphonesIcon color="primary" />
        </ListItemIcon>
        <ListItemText>Support</ListItemText>
      </MenuItem> */}

      {/* FEEDBACK */}
      {viewFeedbackModal ? (
        <SendFeedback
          onClose={() => setViewFeedbackModal(false)}
          open={viewFeedbackModal}
        />
      ) : null}
      <MenuItem disabled onClick={handleFeedback}>
        <ListItemIcon>
          <FAQIcon color="primary" />
        </ListItemIcon>
        <ListItemText>Send Feedback</ListItemText>
      </MenuItem>
      {/* {menuItems.map(({ icon: SvgIcon, ...item }) => (
        <MenuItem key={item.label} onClick={() => navigate(accountPaths.INDEX)}>
          <ListItemIcon>
            <SvgIcon color="primary" />
          </ListItemIcon>
          <ListItemText>{item.label}</ListItemText>
        </MenuItem>
      ))} */}
      <MenuItem onClick={() => navigate(accountPaths.INDEX)}>
        <ListItemIcon>
          <UserIcon color="primary" />
        </ListItemIcon>
        <ListItemText>Account Settings</ListItemText>
      </MenuItem>

      {/* SIGN OUT */}
      <MenuItem onClick={() => logOut()}>
        <ListItemIcon>
          <SignOutIcon color="primary" />
        </ListItemIcon>
        <ListItemText>Sign Out</ListItemText>
      </MenuItem>
    </StyledSubMenuList>
  );
};

const SideBar = ({ className }) => {
  const [openCardsMenu, setOpenCardsMenu] = useState(() =>
    window.location.pathname.includes('cards')
  );
  const navigate = useNavigate();

  const addActiveClass = (path) => (window.location.pathname.match(path) ? 'active' : '');

  const addActiveSubClass = (path) =>
    window.location.pathname.match(path) ? 'active-sub' : '';

  return (
    <aside className={className}>
      <StyledMainMenuList>
        <Logo />
        {/* GET STARTED */}
        {/* <MenuItem
          onClick={() => navigate(userPaths.GET_STARTED)}
          className={addActiveClass(userPaths.GET_STARTED)}
        >
          <ListItemIcon>
            <PowerIcon />
          </ListItemIcon>
          <ListItemText>Get Started</ListItemText>
        </MenuItem> */}
        {/* DASHBOARD */}
        <MenuItem
          onClick={() => navigate(userPaths.DASHBOARD)}
          className={addActiveClass(userPaths.DASHBOARD)}
        >
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText>Dashboard</ListItemText>
        </MenuItem>
        {/* CARDS */}
        <MenuItem
          onClick={() => setOpenCardsMenu(!openCardsMenu)}
          className={addActiveClass(cardPaths.INDEX)}
        >
          <ListItemIcon>
            <CardIcon />
          </ListItemIcon>
          <ListItemText>Cards</ListItemText>
          {openCardsMenu ? <ExpandLess /> : <ExpandMore />}
        </MenuItem>
        <Collapse in={openCardsMenu} timeout="auto" unmountOnExit>
          <MenuList>
            <MenuItem
              onClick={() => navigate(cardPaths.TRANSACTIONS)}
              className={addActiveSubClass(cardPaths.TRANSACTIONS)}
            >
              Transaction
            </MenuItem>
            <MenuItem
              onClick={() => navigate(cardPaths.MY_CARDS)}
              className={addActiveSubClass(cardPaths.MY_CARDS)}
            >
              My Cards
            </MenuItem>
            <MenuItem
              onClick={() => navigate(cardPaths.STATEMENTS)}
              className={addActiveSubClass(cardPaths.STATEMENTS)}
            >
              Statements
            </MenuItem>
            {/* <MenuItem
              onClick={() => navigate(cardPaths.CARD_PAYMENTS)}
              className={addActiveSubClass(cardPaths.CARD_PAYMENTS)}
            >
              Card Repayments
            </MenuItem> */}
          </MenuList>
        </Collapse>
      </StyledMainMenuList>

      <SubMenuItems />
    </aside>
  );
};

const MobileSideBar = () => {
  const [openDrawer, setOpenDrawer] = useState(false);

  const toggleDrawer = () => setOpenDrawer(!openDrawer);
  return (
    <>
      <IconButton onClick={toggleDrawer} className="menu-icon-button">
        <MenuIcon />
      </IconButton>
      <Drawer open={openDrawer} onClose={toggleDrawer} anchor="left">
        <Box width={240}>
          <SideBar />
        </Box>
      </Drawer>
    </>
  );
};

const AppLayout = ({ children }) => {
  React.useEffect(() => {
    // new TawkTo(
    //   process.env.REACT_APP_PUBLIC_PROPERTY_ID,
    //   process.env.REACT_APP_PUBLIC_TAWK_ID
    // );
  }, []);

  return (
    <StyledAppLayout>
      <SideBar className="desktop-sidebar" />
      <MobileSideBar />
      <div className="content">{children}</div>
    </StyledAppLayout>
  );
};

export default AppLayout;

const StyledMainMenuList = styled(MenuList)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  top: 16,
  '> svg': {
    marginLeft: theme.spacing(2)
  },
  '> .MuiMenuItem-root': {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  '.MuiCollapse-root .MuiList-root': {
    paddingLeft: theme.spacing(4)
  },
  '.active': {
    color: theme.palette.secondary.main,
    position: 'relative',
    '&:before': {
      content: '""',
      width: '4px',
      height: '100%',
      position: 'absolute',
      left: 0,
      top: 0,
      background: '#0035A3',
      borderRadius: '0 8px 8px 0'
    }
  },
  '.active-sub': {
    color: theme.palette.secondary.main,
    '&:before': {
      display: 'none'
    }
  }
}));

const StyledSubMenuList = styled(StyledMainMenuList)(() => ({
  top: 'auto',
  bottom: 0
}));

const StyledAppLayout = styled('div')(({ theme }) => ({
  background: theme.palette.background.lightGrey,
  minHeight: '100vh',
  aside: {
    background: theme.palette.background.default,
    width: theme.spacing(30)
  },
  '.desktop-sidebar': {
    position: 'fixed',
    top: 0,
    height: '100%',
    [theme.breakpoints.down('lg')]: {
      display: 'none'
    }
  },
  '.mobile-sidebar': {
    width: theme.spacing(30),
    height: '100%',
    [theme.breakpoints.down('lg')]: {
      display: 'block'
    }
  },
  '.content': {
    marginLeft: theme.spacing(30),
    [theme.breakpoints.down('lg')]: {
      marginLeft: 0
    },
    padding: theme.spacing(3)
  },

  '.menu-icon-button': {
    display: 'none',
    [theme.breakpoints.down('lg')]: {
      display: 'block'
    }
  }
}));
