import { Navigate, Outlet } from 'react-router-dom';
import React from 'react';
import { authPaths } from 'routes/paths';
import useToken from 'hooks/useToken';
import toast from 'react-hot-toast';
import { getDecodedJwt } from 'helpers/authHelper';

const AuthGuard = ({ children }) => {
  const isAuthenticated = useToken()?.length;
  // const { emailVerified } = getDecodedJwt();

  if (!isAuthenticated) {
    return <Navigate to={authPaths.LOGIN} />;
  }

  return children;
};

export default AuthGuard;
