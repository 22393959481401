import pxToRem from 'utils/pxToRem';

const TextField = (theme) => ({
  MuiTextField: {
    defaultProps: {
      variant: 'standard',
      InputLabelProps: {
        shrink: true
      }
    },
    styleOverrides: {
      root: {
        '.MuiInputLabel-root': {
          fontSize: pxToRem(16),
          color: '#00246F'
        },
        'input::placeholder': {
          fontSize: pxToRem(14),
          color: '#4E6FB6'
        },
        'textarea::placeholder': {
          fontSize: pxToRem(14),
          color: '#4E6FB6'
        }
      }
    }
  }
});

export default TextField;
