import { QueryClientProvider, QueryClient } from 'react-query';
import { Toaster } from 'react-hot-toast';
import Routes from 'routes';

const reactQueryConfig = {
  refetchOnWindowFocus: false,
  retry: false
};

const defaultOptions = {
  queries: reactQueryConfig,
  mutations: reactQueryConfig
};

const queryClient = new QueryClient({
  defaultOptions
});

const toastOptions = {
  position: 'top-right',
  duration: 3000,
  border: 'none',
  padding: '16px',
  borderRadius: '4px',
  fontFamily: "'Open Sans', sans-serif"
};

const App = () => (
  <QueryClientProvider client={queryClient}>
    <Routes />
    <Toaster toastOptions={toastOptions} />
    {/* <ReactQueryDevtools initialIsOpen={false} /> */}
  </QueryClientProvider>
);

export default App;
