import React from 'react';
import {
  styled,
  Typography,
  Grid,
  TableRow,
  TableCell,
  TableHead,
  TableBody
} from '@mui/material';
import { v4 as uuid } from 'uuid';
import { useState, useEffect } from 'react';
import pxToRem from 'utils/pxToRem';

export const getLast4Digits = (pan) => pan.slice(-4);

const Table = ({ renderTitle, title, columns = [], data = [], onRowClick }) => {
  const renderTableHead = () =>
    columns.map(({ title }) => (
      <TableCell key={title} sx={{ whiteSpace: 'nowrap' }}>
        {title}
      </TableCell>
    ));

  const renderTableBody = () =>
    data.map((statement) => (
      <TableRow
        key={statement.ID}
        onClick={onRowClick ? () => onRowClick(statement) : () => {}}
        className={onRowClick ? 'clickable' : ''}
      >
        {columns.map(({ key, render }) => (
          <TableCell key={key} sx={{ whiteSpace: 'nowrap' }}>
            {render
              ? render({ row: statement })
              : key === 'maskedPan'
              ? getLast4Digits(statement[key])
              : statement[key] ?? 'N/A'}
          </TableCell>
        ))}
      </TableRow>
    ));

  return (
    <StyledTable>
      <Grid container alignItems="center">
        {renderTitle ? renderTitle() : <Typography variant="h4">{title}</Typography>}
      </Grid>
      <table>
        <TableHead>
          <TableRow>{renderTableHead()}</TableRow>
        </TableHead>

        <TableBody>{renderTableBody()}</TableBody>
      </table>
    </StyledTable>
  );
};

const StyledTable = styled('div')(({ theme }) => ({
  '.MuiTypography': {
    '&-h4': {
      color: theme.palette.text.dark,
      fontSize: pxToRem(32)
    }
  },
  table: {
    width: '75vw',
    borderCollapse: 'collapse',
    [theme.breakpoints.up('md')]: {
      width: '75vw'
    },
    tbody: {
      tr: {
        border: '4px solid #F4F7FA',
        borderLeft: 0,
        borderRight: 0,
        fontWeight: 600
      }
    },
    '.clickable': {
      cursor: 'pointer'
    }
  },
  'td, th': {
    border: 'none',
    textAlign: 'left',
    padding: 24
  },
  th: {
    fontSize: pxToRem(14),
    color: theme.palette.text.dark,
    fontWeight: 400
  },
  tr: {
    background: theme.palette.background.default
  }
}));

export default Table;
