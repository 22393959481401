import { memo } from 'react';
import { styled } from '@mui/material';
import clsx from 'clsx';
import { ReactComponent as Logo } from 'assets/svgs/logo.svg';
import { Link, useLocation } from 'react-router-dom';
import { userPaths } from 'routes/paths';
import pxToRem from 'utils/pxToRem';
import Home from 'pages/Setup/Home';

let activeIndex = 0;

const getMenuItemWithIndex = (menuItems) => {
  const getSetupType = (pathname) => {
    const pathArray = pathname.split('/');
    const setupIndex = pathArray.indexOf('setup');
    if (setupIndex !== -1 && setupIndex + 1 < pathArray.length) {
      const name = pathArray[setupIndex + 1];
      console.log(name);
    }
  };
  const currentPathType = getSetupType(window.location.pathname);

  const orderedPaths = menuItems.map((p) => p.path);
  const activeAndCompletedKeys = orderedPaths.map((path, index) => {
    const splitPath = path.split('/');
    const comparePath = splitPath[splitPath.length - 1];

    if (comparePath === currentPathType) activeIndex = index;

    return {
      isCompleted: activeIndex > index,
      isActive: activeIndex === index,
      totalItems: menuItems.length,
      index
    };
  });

  return menuItems.map((m, index) => ({ ...m, ...activeAndCompletedKeys[index] }));
};

const NavItem = ({ navItem }) => {
  return (
    <StyledNavItem
      className={clsx([
        navItem.isActive ? 'active' : '',
        navItem.isCompleted ? 'completed' : '',
        navItem.index === navItem.totalItems - 1 ? 'last' : ''
      ])}
    >
      <span className="circle" />
      {!navItem.isCompleted && !navItem.isActive ? (
        <span className="link-alt"> {navItem.label}</span>
      ) : (
        <Link to={navItem.path} disabled>
          {navItem.label}
        </Link>
      )}

      <span className="timeline" />
    </StyledNavItem>
  );
};

const OnboardingLayout = ({ children }) => {
  const menuItems = [
    {
      path: userPaths.SETUP_COMPANY_INFO,
      label: 'Company Info'
    },
    {
      path: userPaths.SETUP_BUSINESS_ADDRESS,
      label: 'Business Address'
    },
    {
      path: userPaths.SETUP_BUSINESS_LEADERSHIP,
      label: 'Business Leadership'
    },
    {
      path: userPaths.SETUP_BUSINESS_DETAILS,
      label: 'Business Details'
    },
    {
      path: userPaths.SETUP_BUSINESS_OWNERSHIP,
      label: 'Business Ownership'
    },
    {
      path: userPaths.SETUP_BANK_CONNECTION,
      label: 'Bank Connection'
    },
    {
      path: userPaths.SETUP_TERMS_AND_CONDITIONS,
      label: 'Terms & Conditions'
    }
  ];

  return (
    <StyledOnboardingLayout>
      <aside>
        <Logo />
        <ul>
          <li>
            <Home />
          </li>
          {menuItems.map((item, index) => {
            const navItem = getMenuItemWithIndex(menuItems)[index];
            return <NavItem key={item.label} navItem={navItem} />;
          })}
        </ul>
      </aside>
      <section>{children}</section>
    </StyledOnboardingLayout>
  );
};

export default memo(OnboardingLayout);

const StyledNavItem = styled('li')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  minHeight: 48,
  color: theme.palette.secondary.main,

  fontSize: pxToRem(14),
  '> *': {
    display: 'block'
  },

  '.circle': {
    width: 16,
    height: 16,
    borderRadius: '50%',
    border: `2px solid ${theme.palette.secondary.alt}`
  },

  'a, .link-alt': {
    textDecoration: 'none',
    marginTop: -2,
    marginLeft: 16,
    color: theme.palette.secondary.alt
  },

  '.timeline': {
    width: 2,
    height: '50%',
    position: 'absolute',
    bottom: 0,
    left: 8,
    background: theme.palette.secondary.alt
  },

  '&.active': {
    a: {
      color: theme.palette.text.secondary
    },
    '.timeline': {
      background: theme.palette.secondary.alt
    }
  },

  '&.completed': {
    a: {
      color: theme.palette.text.secondary
    },
    '.circle': {
      border: `4px solid ${theme.palette.primary.dark}`
    }
  }
}));

const StyledOnboardingLayout = styled('div')(({ theme }) => ({
  minHeight: '100vh',
  display: 'flex',
  background: theme.palette.background.lightGrey,
  position: 'relative',

  '.MuiTypography-h1': {
    color: theme.palette.primary.main,
    fontSize: pxToRem(32),
    fontWeight: 600,
    marginBottom: 35
  },

  '> aside': {
    background: theme.palette.background.default,
    padding: '48px 32px',
    width: 296,
    position: 'fixed',
    height: '100vh',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },

    svg: {
      marginBottom: 41
    }
  },

  '> section': {
    margin: '0 auto',
    marginTop: 68
  },

  li: {
    marginBottom: 10
  },

  '.last .timeline': {
    display: 'none'
  }
}));
