export const authPaths = {
  SIGNUP: '/signup',
  LOGIN: '/login',
  VERIFY_EMAIL: '/verify-email',
  FORGOT_PASSWORD: '/forgot-password',
  EMAIL_SENT: '/email-sent',
  PASSWORD_SENT: '/password-sent',
  PASSWORD_CHANGED: '/password-changed',
  RESET_PASSWORD: '/reset-password',
  ENTER_PHONE: '/enter-phone',
  VERIFY_PHONE: '/verify-phone'
};

export const userPaths = {
  DASHBOARD: '/dashboard',
  GET_STARTED: '/get-started',
  TRANSACTION_SETTINGS: '/transactions/settings',
  ACCOUNT_SETTINGS: '/account-settings',
  SETUP_COMPANY_INFO: '/setup/company-info',
  SETUP_BUSINESS_ADDRESS: '/setup/business-address',
  SETUP_BUSINESS_LEADERSHIP: '/setup/business-leadership',
  SETUP_BUSINESS_DETAILS: '/setup/business-details',
  SETUP_BUSINESS_OWNERSHIP: '/setup/business-ownership',
  SETUP_BANK_CONNECTION: '/setup/bank-connection',
  SETUP_TERMS_AND_CONDITIONS: '/setup/terms-and-conditions'
};

export const cardPaths = {
  INDEX: '/cards',
  TRANSACTIONS: '/cards/transactions',
  TRANSACTIONS_SETTING: '/cards/transactions/settings',
  TRANSACTIONS_EXPORT: '/cards/transactions/export',
  STATEMENTS: '/cards/statements',
  MY_CARDS: '/cards/my-cards',
  // CARD_PAYMENTS: '/cards/card-payments',
  REFER_A_BUSINESS: '/cards/refer-business'
};

export const accountPaths = {
  INDEX: '/account'
};
