import {
  Select as MuiSelect,
  InputLabel,
  FormControl,
  MenuItem,
  styled,
  Typography,
  FormHelperText
} from '@mui/material';
import pxToRem from 'utils/pxToRem';

const Select = ({
  options = [],
  name = '',
  label = '',
  value = '',
  placeholder = '',
  error = false,
  helperText,
  onChange = () => {},
  selectProps = {},
  ...restProps
}) => {
  return (
    <StyledFormControl fullWidth variant="standard" {...restProps}>
      <InputLabel shrink>{label}</InputLabel>
      <MuiSelect
        label="Age"
        value={value}
        onChange={onChange}
        name={name}
        placeholder={placeholder}
        error={error}
        {...selectProps}
      >
        {options.map((option, index) => {
          let value;
          let label;
          if (typeof option === 'string') {
            value = option;
            label = option;
          } else {
            value = option?.value;
            label = option?.label;
          }
          return (
            <MenuItem value={value} key={label || index}>
              <Typography variant="caption">{label}</Typography>
            </MenuItem>
          );
        })}
      </MuiSelect>
      {helperText ? <FormHelperText error={error}>{helperText}</FormHelperText> : null}
    </StyledFormControl>
  );
};

export default Select;

const StyledFormControl = styled(FormControl)(() => ({
  '.MuiInputLabel-root': {
    fontSize: pxToRem(16)
  }
}));
