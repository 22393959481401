import pxToRem from 'utils/pxToRem';

const InputLabel = (theme) => ({
  MuiInputLabel: {
    styleOverrides: {
      root: {
        fontSize: pxToRem(14),
        color: '#00246F'
      }
    }
  }
});

export default InputLabel;
