import { Drawer, IconButton, styled, Typography, Grid, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import pxToRem from 'utils/pxToRem';

const ReusableDrawer = ({ title, open, anchor = 'right', onClose, children }) => {
  return (
    <Drawer anchor={anchor} open={open} onClose={onClose}>
      <StyledContent>
        <Grid container className="title" alignItems="center">
          <Grid item xs={10}>
            <Typography variant="h4">{title}</Typography>
          </Grid>
          <Grid item xs={2} textAlign="right">
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Divider />
        {children}
      </StyledContent>
    </Drawer>
  );
};

export default ReusableDrawer;

const StyledContent = styled('div')(({ theme }) => ({
  width: 400,
  maxWidth: '90vw',
  '.title': {
    padding: 16,
    paddingLeft: 32
  },
  '.MuiTypography': {
    '&-h4': {
      fontSize: pxToRem(24),
      color: theme.palette.text.dark,
      fontWeight: 600
    }
  }
}));
