import { memo } from 'react';
import jwt_decode from 'jwt-decode';
import { Navigate } from 'react-router-dom';

export const userTypes = {
  USER: 'USER',
  ADMIN: 'ADMIN'
};

const isUser = () => {
  // subject to change
  return window.localStorage.getItem('evea-token');
};

const authenticators = {
  [userTypes.USER]: isUser
  // extend based on user types
};

export const userAuthenticator = (userTypes = []) => {
  if (!userTypes.length) return true;

  let verdict = false;

  for (let userType of userTypes) {
    const authenticator = authenticators[userType];

    if (!authenticator) {
      console.error('A user type provided in the route config does not exist');
    }

    const isAuthenticated =
      typeof authenticator === 'function' ? authenticator(userType) : false;

    if (isAuthenticated) {
      verdict = true;
      break;
    }
  }

  return verdict;
};

export const Guard = memo(({ allow, children }) => {
  const isAuthenticated = userAuthenticator(allow);

  return isAuthenticated ? children : <Navigate to="/signup" />;
});

export const setToken = (token) => {
  if (!token) return;
  localStorage.setItem('evea-token', token);
};

export const getToken = () => {
  return localStorage.getItem('evea-token');
};

export const getDecodedJwt = () => {
  try {
    const token = getToken();
    const decodedToken = jwt_decode(token);
    return decodedToken;
  } catch (e) {
    return {};
  }
};

export const removeToken = () => {
  localStorage.removeItem('evea-token');
};

export const logOut = () => {
  removeToken();
  window.location.replace('/');
};

export const isAuthenticated = () => {
  // TODO: uncomment lines after @Ibrahim sets an expiry date for the token

  try {
    // const decodedToken = getDecodedJwt();

    // const { exp } = decodedToken;
    // const currentTime = Date.now() / 1000;

    // return exp > currentTime;

    const token = getToken();
    return token;
  } catch (e) {
    return false;
  }
};
