import { Grid, styled, Typography, Chip } from '@mui/material';
import ccBackground from 'assets/svgs/cc-background.svg';
import formatAmount from 'utils/formatAmount';
import pxToRem from 'utils/pxToRem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { ReactComponent as VerveLogo } from 'assets/svgs/verve-logo.svg';

const chipColorMapping = {
  active: {
    color: '#007F7A',
    background: '#E3FCFB'
  },
  'on the way': {
    color: '#007F7A',
    background: '#E3FCFB'
  },
  locked: {
    color: '#007F7A',
    background: '#E3FCFB'
  }
};

const getChipStyle = (status) => ({
  background: chipColorMapping[status].background,
  color: chipColorMapping[status].color,
  borderRadius: '8px',
  textTransform: 'capitalize',
  fontSize: '8px',
  padding: '1px'
});

const CreditCard = ({ card, ...props }) => {
  const { ID, title, holderName, creditLimit, status, lastFour, validityPeriod } = card;

  return (
    <StyledWrapper {...props} key={ID}>
      <div className="white-base" />

      <StyledCreditCard>
        <Grid item xs={12} textAlign="center">
          <Typography variant="h6" className="title">
            {title ?? 'Virtual Card'}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="caption" className="credit-limit-label">
            Credit Card
          </Typography>
          <Typography variant="body1" className="credit-limit-amount">
            {formatAmount(creditLimit)}
          </Typography>
          <Typography variant="caption" className="available-limit-label">
            Available Spend
          </Typography>
        </Grid>

        <Grid item xs={6} textAlign="right">
          <div>
            <MoreHorizIcon />
          </div>
          <div>
            <Chip label={status} sx={getChipStyle(status)} />
          </div>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1" className="last-four">
            {lastFour}
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <div className="validity-period">
            <Typography>
              VALID
              <br />
              THRU
            </Typography>
            <Typography>{validityPeriod}</Typography>
          </div>
        </Grid>
        <Grid item xs={4}>
          ***
        </Grid>

        <Grid item xs={6} sx={{ mt: '10px' }}>
          <Typography>{holderName}</Typography>
        </Grid>
        <Grid item xs={6} textAlign="right" sx={{ mt: '10px' }}>
          <VerveLogo />
        </Grid>
      </StyledCreditCard>
    </StyledWrapper>
  );
};

const StyledCreditCard = styled((props) => <Grid {...props} container />)(
  ({ theme }) => ({
    background: `url(${ccBackground})`,
    backgroundSize: 'cover',
    color: 'white',
    paddingLeft: 32,
    paddingRight: 32,
    paddingBottom: '18px',
    width: 370,

    '.title': {
      textAlign: 'center',
      color: theme.palette.text.dark,
      position: 'relative',
      fontWeight: 600,
      fontSize: pxToRem(14)
    },
    '.credit-limit': {
      '&-label': {
        fontSize: pxToRem(10),
        fontWeight: 400,
        lineHeight: '12px',
        opacity: 0.7
      },
      '&-amount': {
        fontSize: pxToRem(22),
        fontWeight: 700,
        lineHeight: '27px'
      }
    },
    '.available-limit': {
      '&-label': {
        fontSize: pxToRem(9),
        opacity: 0.7,
        lineHeight: '11px',
        position: 'relative',
        top: '-5px'
      }
    },
    '.last-four': {
      letterSpacing: '7.9px',
      marginTop: 8,
      marginBottom: 8
    },
    '.validity-period': {
      display: 'flex',
      alignItems: 'center',
      '.MuiTypography-root': {
        '&:first-of-type': {
          fontSize: '5px',
          marginRight: 3,
          opacity: 0.7
        },
        '&:last-of-type': {
          letterSpacing: '3.4px',
          opacity: 0.7
        }
      }
    }
  })
);

const StyledWrapper = styled('div')(() => ({
  maxWidth: 328,
  position: 'relative',
  '.white-base': {
    background: 'white',
    position: 'relative',
    bottom: -30,
    height: 30,
    margin: '0 auto',
    zIndex: -1
  },
  '.card': {
    position: 'absolute',
    width: '100%',
    top: 0,
    left: 0
  }
}));

export default CreditCard;
