const primary = {
  main: '#0035A3',
  dark: '#5685FF'
};

const secondary = {
  main: '#1A63FF',
  alt: '#5675B9',
  greyLine: '#DFE1E6'
};

const text = {
  primary: '#2F3034',
  placeholder: '#4E6FB6',
  secondary: '#0447F1',
  altSecondary: '#5675B9',
  dark: '#00246F',
  darkGrey: '#091E42',
  black: '#000000',
  green: '#007F7A',
  bodyBlack: '#344563',
  label: '#ACACAC'
};

const background = {
  default: '#FFFFFF',
  grey: '#E5E5E5',
  lightGrey: '#F4F7FA',
  greyAlt: '#E5ECFB',
  greyStroke: '#DDE8FE'
};

const palette = {
  primary,
  secondary,
  text,
  background
};

export default palette;
