import { lazy } from 'react';
import OnboardingLayout from 'components/layouts/OnboardingLayout';
import AppLayout from 'components/layouts/AppLayout';

export const routes = [
  {
    path: '/*',
    component: lazy(() => import('pages/auth')),
    isAuthenticated: false,
    useGeneralLoader: false
  },

  {
    path: '/get-started',
    component: lazy(() => import('pages/Dashboard/GetStarted')),
    isAuthenticated: true,
    useGeneralLoader: false,
    layout: AppLayout
  },
  {
    path: '/dashboard/*',
    component: lazy(() => import('pages/Dashboard')),
    layout: AppLayout,
    isAuthenticated: true,
    useGeneralLoader: true
  },
  {
    path: '/setup/*',
    component: lazy(() => import('pages/Setup')),
    layout: OnboardingLayout,
    isAuthenticated: true,
    useGeneralLoader: true
  },
  {
    path: '/cards/*',
    component: lazy(() => import('pages/Card')),
    layout: AppLayout,
    isAuthenticated: true,
    useGeneralLoader: true
  },
  {
    path: '/account/*',
    component: lazy(() => import('pages/Account')),
    layout: AppLayout,
    isAuthenticated: true,
    useGeneralLoader: true
  },
  {
    path: '*',
    component: lazy(() => import('components/errorPages/NotFound'))
  }
];
