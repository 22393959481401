const typography = {
  fontFamily: ["'Open Sans'", "'Source Sans Pro'", 'sans-serif'].join(','),
  body1: {
    fontFamily: "'Open Sans'"
  },
  body2: {
    fontFamily: "'Open Sans'"
  },
  caption: {
    fontFamily: "'Open Sans'"
  },
  h1: {
    fontFamily: "'Source Sans Pro'",
    fontSize: 'calc(32/16)px'
  },
  h2: {
    fontFamily: "'Source Sans Pro'"
  },
  h3: {
    fontFamily: "'Source Sans Pro'"
  },
  h4: {
    fontFamily: "'Source Sans Pro'"
  },
  h5: {
    fontFamily: "'Source Sans Pro'"
  },
  h6: {
    fontFamily: "'Source Sans Pro'"
  }
};

export default typography;
