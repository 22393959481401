import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  styled,
  DialogActions
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ReactComponent as CloseIcon } from 'assets/svgs/closeIcon.svg';
import pxToRem from 'utils/pxToRem';

export default function Modal({
  title,
  alignTitle = 'left',
  closable = true,
  open,
  onClose,
  primaryActionText,
  secondaryActionText,
  onPrimaryAction = () => {},
  onSecondaryAction = () => {},
  children,
  contentProps = {}
}) {
  const titleNode = (
    <DialogTitle sx={{ textAlign: alignTitle, alignItems: 'center' }}>
      {title}
      {closable ? (
        <IconButton aria-label="close" onClick={onClose} size="large">
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );

  const actionsNode = (
    <DialogActions sx={getActionStyle({ primaryActionText, secondaryActionText })}>
      {secondaryActionText ? (
        <LoadingButton onClick={onSecondaryAction} variant="outlined" color="secondary">
          {secondaryActionText}
        </LoadingButton>
      ) : null}
      {primaryActionText ? (
        <LoadingButton onClick={onPrimaryAction} variant="contained">
          {primaryActionText}
        </LoadingButton>
      ) : null}
    </DialogActions>
  );

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      fullWidth
      PaperProps={{ sx: { borderRadius: 0 } }}
    >
      {title ? titleNode : null}
      <DialogContent sx={{ pb: 0, overflowY: 'unset' }} {...contentProps}>
        {children}
        {primaryActionText || secondaryActionText ? actionsNode : null}
      </DialogContent>
    </StyledDialog>
  );
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  background: 'rgba(0, 46, 145, 0.6)',

  '.MuiDialog-container > .MuiPaper-root': {
    borderRadius: 8,
    width: 509
  },

  '.MuiDialogTitle-root': {
    position: 'relative',
    fontSize: pxToRem(24),
    color: theme.palette.text.dark,
    fontWeight: 600
  },

  '.MuiIconButton-root': {
    position: 'absolute',
    right: 26,
    top: '50%',
    transform: 'translateY(-50%)'
  },

  '.MuiDialogActions-root': {
    padding: 0,
    marginTop: 10,
    marginBottom: 20,
    flexWrap: 'wrap',

    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      rowGap: 16
    },

    '.MuiLoadingButton-root': {
      height: 48,
      [theme.breakpoints.down('md')]: {
        width: '100%'
      }
    }
  }
}));

const getActionStyle = ({ primaryActionText, secondaryActionText }) => ({
  justifyContent: secondaryActionText && primaryActionText ? 'space-between' : 'flex-end'
});
