import Button from './Button';
import TextField from './TextField';
import InputLabel from './InputLabel';

const ComponentOverrides = (theme) => ({
  ...Button(theme),
  ...TextField(theme),
  ...InputLabel(theme)
});

export default ComponentOverrides;
