import * as React from 'react';
import { styled } from '@mui/material/styles';
import { FormControlLabel, FormHelperText, Radio, RadioGroup } from '@mui/material';
import { v4 as uuid } from 'uuid';

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.1), inset 0 -1px 0 rgba(16,22,26,.1)',
  // border: '1px solid #E5ECFB',
  // boxSizing: "border-box",
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#FEFEFE',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5'
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)'
  }
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#137cbd',
  backgroundImage:
    'linear-gradient(180deg,hsla(221, 100%, 55%, 1),hsla(221, 100%, 55%, 1))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""'
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3'
  }
});

function BpRadio(props) {
  return (
    <Radio
      sx={{
        '&:hover': {
          bgcolor: 'transparent'
        }
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

const RadioButton = ({
  radioItemValue,
  helperText,
  labelText,
  formName,
  onChange,
  radioValue
}) => {
  return (
    <>
      <RadioGroup
        aria-labelledby="radio button section"
        name={formName}
        value={radioValue}
        onChange={onChange}
      >
        {radioItemValue.map((radioItem, index) => {
          return (
            <FormControlLabel
              value={radioItem.radioItem}
              control={<BpRadio />}
              label={radioItem.radioLabel}
              key={uuid()}
            />
          );
        })}
      </RadioGroup>
      <FormHelperText>{helperText}</FormHelperText>
    </>
  );
};
export default RadioButton;
