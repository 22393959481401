import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { CircularProgress, InputLabel, styled, Typography, Box } from '@mui/material';
import { ReactComponent as UploadCloud } from 'assets/svgs/uploadCloud.svg';
import pxToRem from 'utils/pxToRem';
import toast from 'react-hot-toast';
import { useFileUploadHook } from 'hooks/useFileUploadHook';

function FileUpload({
  sizeLimit,
  acceptedFiles,
  label,
  fileName,
  onSuccess,
  isFileLoading
}) {
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const { progress, isLoading, handleFileChange } = useFileUploadHook();
  const [file, setFile] = useState();

  const bytesToMB = (bytes) => {
    return bytes / (1024 * 1024);
  };

  const validator = (file) => {
    const allowedTypes = ['application/pdf', 'image/jpeg', 'image/jpg'];
    if (!allowedTypes.includes(file.type)) {
      toast.error('Only PDF and JPG files are allowed');
      return {
        code: 'Invalid file type',
        message: 'Only PDF and JPG files are allowed'
      };
    }

    let maxLength = 5242880;
    const fileSizeInMB = bytesToMB(maxLength);
    if (file.size > maxLength) {
      toast.error(`Size is larger than ${fileSizeInMB} MB characters`);
      return {
        code: 'File size too large',
        message: `Size is larger than ${fileSizeInMB} characters`
      };
    }

    return null;
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles[0]) {
        setIsFileUploaded(true);
        setFile(acceptedFiles[0]);
        handleFileChange(
          acceptedFiles[0],
          (e) => onSuccess && onSuccess(e),
          (e) => isFileLoading && isFileLoading(e)
        );
      }
    },
    [handleFileChange, isFileLoading, onSuccess]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    validator,
    onDrop
  });

  const getAcceptedFilesText = () => {
    return acceptedFiles
      .join(',')
      .replace(',', ', ')
      .replace(/,(?!.*,)/, ' or ');
  };

  const acceptedFilesText = !acceptedFiles?.length ? '' : `(${getAcceptedFilesText()})`;

  return (
    <>
      <InputLabel sx={labelStyle}>{label}</InputLabel>
      <StyledDiv {...getRootProps()}>
        {isLoading ? (
          <CircularProgressWithLabel value={progress} />
        ) : (
          <>
            <input {...getInputProps()} />
            {isDragActive ? (
              <Typography variant="body2" color="secondary">
                Drop the files here ...
              </Typography>
            ) : isFileUploaded && file ? (
              <Typography
                variant="body2"
                color="secondary"
                noWrap
                style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
              >
                {file.name}
              </Typography>
            ) : fileName ? (
              <Typography
                variant="body2"
                color="secondary"
                noWrap
                style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
              >
                {fileName}
              </Typography>
            ) : (
              <>
                <UploadCloud />
                <Typography variant="body2" color="primary">
                  Drop files here or <strong>browse</strong>
                </Typography>
                <Typography variant="caption" color="text.placeholder">
                  Size limit: {sizeLimit}MB {acceptedFilesText}
                </Typography>
              </>
            )}
          </>
        )}
      </StyledDiv>
    </>
  );
}

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${props.value}%`}
        </Typography>
      </Box>
    </Box>
  );
}

export default FileUpload;

const labelStyle = (theme) => ({
  color: theme.palette.text.dark,
  fontSize: pxToRem(14),
  mb: 1
});

const StyledDiv = styled('div')(({ theme }) => ({
  background: theme.palette.background.greyAlt,
  border: '1px dashed #E5ECFB',
  borderRadius: 4,
  padding: 22,
  paddingBottom: 15,
  textAlign: 'center',
  cursor: 'pointer'
}));
