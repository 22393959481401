import AWS from 'aws-sdk';
import { useState } from 'react';
import toast from 'react-hot-toast';

//
export const useFileUploadHook = () => {
  //
  const [url, setFile] = useState('');
  const [progress, setProgress] = useState(0);
  const [isLoading, setLoading] = useState(false);
  // S3 Bucket Name
  const S3_BUCKET = process.env.REACT_APP_AWS_BUCKET;
  // S3 Region
  const REGION = process.env.REACT_APP_AWS_REGION;
  // S3 Credentials
  const s3 = new AWS.S3({
    region: REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY
  });
  // Uploading file to s3
  const handleFileChange = async (file, onSuccess = () => {}, isLoading = () => {}) => {
    isLoading(true);
    // Files Parameters
    const { type, name } = file;
    const key = `evea/${Date.now()}_${name}`;
    const params = {
      Bucket: S3_BUCKET,
      Body: file,
      Key: key,
      ACL: 'public-read',
      ContentType: type
    };
    //
    try {
      const data = await s3
        .upload(params)
        .on('httpUploadProgress', (evt) => {
          setLoading(true);
          // File uploading progress
          setProgress(parseInt((evt.loaded * 100) / evt.total));
        })
        .promise();
      if (data) {
        setLoading(false);
        setFile(data.Location);
        onSuccess(data.Location);
      }
      isLoading(false);
    } catch (error) {
      isLoading(false);
      toast.error(error);
    }
  };

  return { url, progress, isLoading, handleFileChange };
};
