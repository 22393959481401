import { Box, Button, Divider, styled, TextField, Typography } from '@mui/material';
import { Modal, RadioButton } from 'components/reusables';
import pxToRem from 'utils/pxToRem';

const SendFeedback = ({ open, onClose }) => {
  const radioLabels = [
    {
      radioItem: 'feedback1',
      radioLabel: "I can't seem to find something"
    },
    {
      radioItem: 'feedback2',
      radioLabel: "I'm at a loss as to how to do something"
    },
    {
      radioItem: 'feedback3',
      radioLabel: "I figured out how to do something but it wasn't as simple as it seemed"
    },
    {
      radioItem: 'feedback14',
      radioLabel: 'I love it!'
    },
    {
      radioItem: 'feedback5',
      radioLabel: 'Something else'
    }
  ];
  const handleRadioChange = (e) => {};

  return (
    <Modal open={open} onClose={onClose} alignTitle="left" closable>
      <StyledContent>
        <Typography variant="h3">Help us improve Evea</Typography>
        <Divider />
        <Box className="feedback-body">
          <Typography variant="h6">
            Have something to say about a feature? Please notify us.
          </Typography>
          <RadioButton radioItemValue={radioLabels} onChange={handleRadioChange} />
          <Typography className="more-info"> Kindly share More Info.</Typography>
          <StyledTextfield
            placeholder="Input Notes here..."
            fullWidth
            id="filled-multiline-flexible"
            label="Note"
            multiline
            sx={{ backgroundColor: '#ffffff', outline: 'none', px: 1 }}
            rows={4}
            variant="standard"
          />
          <Box className="button-container">
            <Button size="small" onClick={onClose} variant="outlined">
              Cancel
            </Button>
            <Button
              size="small"
              // TODO: Create an email handler to send feedback
              onClick={onClose}
              variant="contained"
              sx={{ ml: 3 }}
            >
              Continue
            </Button>
          </Box>
        </Box>
      </StyledContent>
    </Modal>
  );
};

const StyledContent = styled('div')(({ theme }) => ({
  position: 'relative',
  textAlign: 'left',

  '.MuiTypography-h3': {
    fontSize: pxToRem(24),
    color: '#0035A3',
    lineHeight: '30px',
    textAlign: 'center',
    fontWeight: 600,
    marginBottom: 15
  },
  '.MuiDivider-root': {
    width: '125%',
    marginLeft: '-23px'
  },
  '.feedback-body': {
    padding: '0 30px 30px ',
    [theme.breakpoints.down('md')]: {
      padding: 20
    },
    [theme.breakpoints.down('sm')]: {
      padding: 2
    }
  },
  '.MuiTypography-h6': {
    fontSize: pxToRem(16),
    color: '#0035A3',
    lineHeight: pxToRem(20),
    textAlign: 'center',
    fontWeight: 600,
    marginBottom: 25,
    marginTop: 10
  },
  '.more-info': {
    fontSize: pxToRem(14),
    color: '#0035A3',
    lineHeight: pxToRem(18),
    fontWeight: 600,
    marginBottom: 5,
    marginTop: 20
  },
  '.button-container': {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'center'
  },
  '.MuiButton-root': {
    marginTop: 24,
    marginBottom: 30
  },
  '.css-1go61kk-MuiTypography-root  ': {
    fontSize: pxToRem(14),
    color: '#0035A3',
    lineHeight: pxToRem(18),
    fontWeight: 600
  }
}));
const StyledTextfield = styled(TextField)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  border: '1px solid #0035A3',
  borderRadius: '8px',
  // paddingLeft: 10,
  '&: after': {
    // padding: '10px',
    borderBottom: 'none'
  },
  '.css-la280a-MuiInputBase-root-MuiInput-root:before ': {
    borderBottom: 'none'
  },
  '&.MuiInput-underline MuiInputBase-root': {
    display: 'none'
  },
  '.css-11vd2op-MuiFormLabel-root-MuiInputLabel-root': {
    padding: '16px',
    fontSize: pxToRem(16),
    fontWeight: 600,
    lineHeight: pxToRem(18),
    color: '#00246F'
  },
  '.css-la280a-MuiInputBase-root-MuiInput-root:after': {
    borderBottom: 'none'
  }
}));

export default SendFeedback;
