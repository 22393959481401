import { GlobalStyles as GlobalThemeStyles } from '@mui/material';

export default function GlobalStyles() {
  return (
    <GlobalThemeStyles
      styles={{
        '*': {
          margin: 0,
          padding: 0,
          boxSizing: 'border-box'
        },
        html: {
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch'
        },
        body: {
          width: '100%',
          height: '100%',
          fontFamily: "Open Sans', sans-serif"
        },
        '#root': {
          width: '100%',
          height: '100%'
        },
        img: { display: 'block', maxWidth: '100%' }
      }}
    />
  );
}
