import { Typography, IconButton, styled } from '@mui/material';
import { ReactComponent as BackIcon } from 'assets/svgs/expandCircleLeft.svg';
import pxToRem from 'utils/pxToRem';

const PageTitle = ({ title, onBack }) => {
  return (
    <StyledPageTitle variant="h1">
      <IconButton onClick={onBack}>
        <BackIcon />
      </IconButton>
      {title}
    </StyledPageTitle>
  );
};

export default PageTitle;

const StyledPageTitle = styled((props) => <Typography {...props} variant="h1" />)(
  ({ theme }) => ({
    color: theme.palette.text.darkGrey,
    fontWeight: 600,
    fontSize: pxToRem(32),
    lineHeight: '40px',

    '.MuiIconButton-root': {
      marginTop: -5
    }
  })
);
