const Button = (theme) => ({
  MuiButton: {
    defaultProps: {
      disableTouchRipple: true
    },
    styleOverrides: {
      root: {
        fontWeight: 400,
        borderRadius: '100px',
        textTransform: 'none',
        padding: '13px 32px',
        '&:hover': {
          boxShadow: 'none'
        }
      }
    }
  }
});

export default Button;
