// src/components/HomeIcon.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { userPaths } from 'routes/paths';

const Home = () => {
  const navigate = useNavigate();

  return (
    <svg
      onClick={() => navigate(userPaths.DASHBOARD)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: 'pointer', color: 'blue' }}
    >
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" fill="currentColor" />
    </svg>
  );
};

export default Home;
