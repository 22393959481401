import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

const DatePicker = ({
  name,
  label,
  value,
  placeholder = '',
  onChange = () => {},
  fullWidth,
  inputParams = {},
  error = false,
  ...rest
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        onChange={onChange}
        label={label}
        name={name}
        value={value}
        {...rest}
        slotProps={{ textField: { ...inputParams } }}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
